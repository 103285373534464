import { keyframes } from 'styled-components';

import {
  getDuration,
  getTotalPrice,
  getParkingCost,
  getFeeAmount,
  getValidationAmount,
  getSiteName,
  getSiteAddress1,
  getSiteAddress2,
  getTaxAmount,
  getCoverageDuration,
  getBillableDuration,
} from 'apps/pay/utils/visitUtils';
import { ReactComponent as HelpIcon } from 'assets/svg/help.svg';
import { ReactComponent as InfoIcon } from 'assets/svg/info.svg';
import { ReactComponent as CallIcon } from 'assets/svg/support-operator.svg';
import { Box } from 'components/uikit';
import { fadeIn } from 'styled/keyframes';
import { selectableTextCss } from 'styled/mixins';

import { NextLevelParking } from '../NextLevelParking';
import { PageSection } from '../Page';
import { Separator } from '../Separator';
import { Text } from '../Text';

import { LineItem } from './LineItem';
import { PaidTime } from './PaidTime';
import { PaymentMethod } from './PaymentMethod';
import * as Styled from './Receipt.styled';

import { type Visit } from 'types/api';

interface ReceiptProps {
  visit: Visit | null;
  onClickSignup: () => void;
  openServiceFeeInfo: () => void;
}

const sectionReveal = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0, -40px, 0);
  }
  25% {
    opacity: 0;
  }
  85% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

export function Receipt({ visit, onClickSignup, openServiceFeeInfo }: ReceiptProps) {
  const hasCoverage = !!visit?.coverage?.reservations?.length;
  return (
    <>
      <PageSection
        css={`
          animation: ${sectionReveal} 800ms ease 200ms 1 backwards;
        `}
      >
        <Styled.Heading
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="baseline"
          width="100%"
          fontSize="32px"
          marginBottom="32px"
        >
          <span>Total</span>
          <span>{getTotalPrice(visit)}</span>
        </Styled.Heading>
        <Styled.Heading>Location</Styled.Heading>
        <Box
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          paddingTop="8px"
          marginTop="8px"
          css={selectableTextCss}
        >
          <Text fontSize="18px" lineHeight={1.3} color="#000">
            {getSiteName(visit)}
          </Text>
          <Text fontSize="18px" lineHeight={1.3} color="#000">
            {getSiteAddress1(visit)}
          </Text>
          <Text fontSize="18px" lineHeight={1.3} color="#000">
            {getSiteAddress2(visit)}
          </Text>
        </Box>
      </PageSection>
      <Separator
        css={`
          animation: ${fadeIn} 800ms ease 400ms 1 backwards;
        `}
      />
      <PageSection
        css={`
          animation: ${sectionReveal} 800ms ease 300ms 1 backwards;
        `}
      >
        <Styled.Heading>Cost</Styled.Heading>
        <Box
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          width="100%"
          height="auto"
          flexShrink={0}
        >
          {!!visit?.coverage?.reservations?.length && (
            <LineItem
              label="Covered by reservation"
              secondaryLabel={getCoverageDuration(visit.coverage?.reservations)}
              value="$0.00"
            />
          )}
          {!!visit?.coverage?.parkingPasses?.length && (
            <LineItem
              label="Covered by pass"
              secondaryLabel={getCoverageDuration(visit.coverage?.parkingPasses)}
              value="$0.00"
            />
          )}
          <LineItem
            label={hasCoverage ? 'Additional time' : 'Parking cost'}
            secondaryLabel={hasCoverage ? getBillableDuration(visit) : getDuration(visit)}
            value={getParkingCost(visit)}
          />
          {getValidationAmount(visit) && (
            <LineItem
              label="Validation"
              value={<Text color="#06944F">{getValidationAmount(visit)}</Text>}
            />
          )}
          {getTaxAmount(visit) && <LineItem label="Tax" value={getTaxAmount(visit)} />}
          {getFeeAmount(visit) && (
            <LineItem
              label={
                <Box
                  flexDirection="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  width="100%"
                  flex={1}
                >
                  Service fee
                  <Styled.FeeInfoButton onClick={openServiceFeeInfo}>
                    <InfoIcon />
                  </Styled.FeeInfoButton>
                </Box>
              }
              value={getFeeAmount(visit)}
            />
          )}
          <LineItem
            label="Duration"
            value={getDuration(visit)}
            marginTop="16px"
            paddingTop="32px"
            paddingBottom={0}
            borderTop="1px solid rgba(0,0,0,0.2)"
          />
        </Box>
      </PageSection>
      <Separator
        css={`
          animation: ${fadeIn} 800ms ease 500ms 1 backwards;
        `}
      />
      <PageSection
        css={`
          animation: ${sectionReveal} 800ms ease 400ms 1 backwards;
        `}
      >
        <Styled.Heading>Payment method</Styled.Heading>
        {visit && !visit.open && <PaidTime paymentTimestamp={visit.updatedAt} />}
        <PaymentMethod paymentInfo={visit?.oneTimePaymentMethodInfo} />
      </PageSection>
      <Separator
        css={`
          animation: ${fadeIn} 600ms ease 700ms 1 backwards;
        `}
      />
      <PageSection
        css={`
          animation: ${sectionReveal} 600ms ease 600ms 1 backwards;
        `}
      >
        <NextLevelParking onClickSignup={onClickSignup} />
      </PageSection>
      <Separator
        css={`
          animation: ${fadeIn} 400ms ease 900ms 1 backwards;
        `}
        marginBottom="0"
      />
      <PageSection
        css={`
          width: 100%;
          overflow: hidden;
          animation: ${sectionReveal} 400ms ease 800ms 1 backwards;
        `}
      >
        <Styled.HelpLink
          href="https://metropolishelp.zendesk.com/hc/en-us"
          target="_blank"
          rel="noopener noreferrer"
        >
          <HelpIcon />
          <Text marginLeft="8px" fontSize="16px" lineHeight={1} letterSpacing="0.01em">
            Get help
          </Text>
        </Styled.HelpLink>
        <Separator marginY="15px" marginLeft="33px" />
        <Styled.HelpLink href="tel:+18564859703">
          <CallIcon />
          <Text marginLeft="8px" fontSize="16px" lineHeight={1} letterSpacing="0.01em">
            Call us
          </Text>
        </Styled.HelpLink>
      </PageSection>
    </>
  );
}
